<template>
  <div v-page-behavior="{eventSource: assist.dataType.EventSourceEnum.PRE_ORIGINATION_ENTER_LEAVE_CONFIRM_LOAN_DETAILS.value, path: $route.path}">
    <div class="main-content">
      <v-card
        flat
        color="transparent"
        class="loan-summary"
      >
        <v-card-text>
          <v-row dense>
            <v-col cols="5">
              <div class="field-label">Loan #</div>
            </v-col>
            <v-col cols="7" align="right">
              <div class="field-value loan-no">{{ layout.data.loanNo }}</div>
            </v-col>
            <v-col cols="5">
              <div class="field-label">Amount</div>
            </v-col>
            <v-col cols="7" align="right">
              <div class="field-value loan-amount">{{ assist.currency.format(layout.data.loanAmount) }}</div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card-actions>
        <a
          @click="layout.data.showPreview = true"
        >Preview Payment Plan</a>
      </v-card-actions>

      <div v-min-height="300">
        <v-sheet class="loan-details">
          <v-row
            no-gutters
          >
            <v-col
              cols="6"
              v-for="(item, index) of layout.setting.paymentLayoutSetting"
              :key="index"
              class="field pl-4"
            >
              <div class="field-label">{{ item.text }}</div>
              <div :style="getStyle(item.isRight)" class="field-value">{{ layout.data.paymentData[item.fieldKey] || 'N/A' }}</div>
            </v-col>
          </v-row>
        </v-sheet>
      </div>
      <v-alert
        dense
        text
        border="left"
        color="var(--theme_primary)"
        class="f-s-12"
      >
        You may follow the given minimum Payment Schedule or manage your loan payment schedule in your account.
        With {{ layout.data.portfolioName }} you have the flexibility of paying your loan in full or pay down at any time without
        prepayment penalties. Remember to arrange the Pay Down or Pay In Full payment 3 business days before the due
        date. If you choose to pay down, you can add $20.00 or more to your scheduled payment to pay the loan faster and
        reduce the number of payments.
      </v-alert>
    </div>
    <div class="footer-wrap">
      <wap-btn
        theme="primary"
        :block="true"
        width="100%"
        @click="onClickAction"
      >Continue
      </wap-btn>
    </div>

    <preview-payment-schedule
      v-model="layout.data.showPreview"
      :paymentInstallment="layout.data.previewInstallmentList"
      :regular-amount="layout.data.paymentData.regularAmount"
      height="50"
      title="Payment Plan"
      @confirm="layout.data.showPreview = false"
    >
    </preview-payment-schedule>
  </div>
</template>

<script>
import { OnlineApi } from '@/api'
import { Tool, DataType, Currency } from '@/assets/js/util'
import WapBtn from '@/components/base/WapBtn'
import PreviewPaymentSchedule from '@/components/disbursement/PreviewPaymentSchedule'

const tool = new Tool()
const currency = new Currency()

export default {
  name: 'loan-details',
  components: {
    WapBtn,
    PreviewPaymentSchedule
  },
  data () {
    return {
      assist: {
        tool: tool,
        dataType: DataType,
        currency: currency
      },
      layout: {
        setting: {
          paymentLayoutSetting: [
            {
              text: 'Disbursement Date',
              fieldKey: 'effectiveDate'
            },
            {
              text: 'Regular Installment Amount',
              fieldKey: 'regularAmount',
              isRight: true
            },
            {
              text: 'First Due Date',
              fieldKey: 'firstDueDate'
            },
            {
              text: 'First Payment Amount',
              fieldKey: 'firstPaymentAmount',
              isRight: true
            },
            {
              text: 'Maturity Date',
              fieldKey: 'maturityDate'
            },
            {
              text: 'Finance Charge',
              fieldKey: 'financeCharge',
              isRight: true
            },
            {
              text: 'Total # of Installments',
              fieldKey: 'totalNumOfPayment'
            },
            {
              text: 'Total $ of Installments',
              fieldKey: 'totalAmountOfPayment',
              isRight: true
            },
            {
              text: 'Payment Frequency',
              fieldKey: 'payrollFrequency'
            },
            {
              text: 'APR',
              fieldKey: 'apr',
              isRight: true
            }
          ]
        },
        data: {
          loanNo: '',
          loanAmount: null,
          loanId: null,
          portfolioName: null,
          paymentData: {
            effectiveDate: '',
            firstDueDate: '',
            maturityDate: '',
            payrollFrequency: '',
            firstPaymentAmount: '',
            regularAmount: '',
            financeCharge: '',
            totalNumOfPayment: '',
            totalAmountOfPayment: '',
            apr: ''
          },
          showPreview: false,
          previewInstallmentList: []
        }
      }
    }
  },
  mounted () {
    this.layout.data.loanAmount = this.$store.getters.getApprovedAmount
    this.layout.data.loanNo = this.$store.getters.getLoan.loanNo
    if (tool.isNotEmpty(this.$store.getters.getPortfolioJson)) {
      this.layout.data.portfolioName = this.$store.getters.getPortfolioJson.name
    }
    this.getPaymentDetail()
  },
  watch: {
    'store.getters.getPortfolioJson': {
      handler: function () {
        if (tool.isNotEmpty(this.$store.getters.getPortfolioJson)) {
          this.layout.data.portfolioName = this.$store.getters.getPortfolioJson.name
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    onClickAction () {
      this.$router.push({
        path: '/disbursement/sign-loan-agreement'
      })
    },
    getStyle (isRight) {
      return isRight ? 'text-align: right' : 'text-align: left'
    },
    getPaymentDetail () {
      OnlineApi.getLoanDetail((result) => {
        if (tool.isNotEmpty(result)) {
          this.layout.data.paymentData.effectiveDate = result.effectiveDate
          this.layout.data.paymentData.firstDueDate = result.firstDebitDate
          this.layout.data.paymentData.maturityDate = result.lastDebitDate
          this.layout.data.paymentData.firstPaymentAmount = currency.format(result.firstPaymentAmount)
          this.layout.data.paymentData.regularAmount = currency.format(result.regularAmount)
          this.layout.data.paymentData.financeCharge = currency.format(result.totalInterest)
          this.layout.data.paymentData.totalNumOfPayment = result.debitCount
          this.layout.data.paymentData.totalAmountOfPayment = currency.format(result.totalAmount)
          this.layout.data.paymentData.apr = tool.formatPercent(result.apr)
          const installments = []
          result.installments.map((item) => {
            installments.push({ ...item, regularAmount: result.regularAmount })
          })
          this.layout.data.previewInstallmentList = installments
          for (const item in DataType.PayrollFrequencyEnum) {
            if (DataType.PayrollFrequencyEnum[item].value === result.payrollFrequency) {
              this.layout.data.paymentData.payrollFrequency = DataType.PayrollFrequencyEnum[item].text
            }
          }
        }
      }, (failure) => {
      })
    }
  }
}
</script>

<style scoped lang="sass">
  .field-label
    color: #9CA0AB
  .field-value
    font-weight: 900

  .loan-summary
    font-size: 14px
    & .loan-no
      font-size: 14px
      color: var(--theme_secondary)
    & .loan-amount
      font-size: 18px
      color: var(--theme_primary)

  .loan-details
    color: #F5F5F5!important
    background-color: #F4F5F9
    border-radius: 16px
    padding: 8px
    & .field
      height: 50px
      padding: 4px
      text-align: left
    & .field-label
      font-size: 10px
      color: #9CA0AB
      white-space: nowrap
    & .field-value
      font-size: 18px
      color: #000410
</style>
